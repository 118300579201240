<template>
  <div class="assets-type-label" @click="clickItem">
    <span>{{data.code + '  ' + data.name}}</span>
    <fm-btn v-if="isSelect" @click="chooseIt" class="assets-type-label-btn" style="margin-left: 1rem;" size="small">选择</fm-btn>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    isSelect: {
      type: Boolean
    }
  },
  methods: {
    clickItem () {
      this.$emit('click')
    },
    chooseIt () {
      this.$emit('change', this.data)
    }
  }
}
</script>

<style lang="less" scoped>
.assets-type-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 5px;
  height: 22px;
}
.assets-type-label-btn {
  display: none;
}
.assets-type-label:hover {
  .assets-type-label-btn {
    display: inline-flex;
  }
}
</style>