<template>
  <div style="margin: 1rem;width:calc(100% - 2rem);height:calc(100% - 2rem)">
    <assets-type-tree></assets-type-tree>
  </div>
</template>

<script>
import AssetsTypeTree from './assets_type/assetsTypeTree'

export default {
  components: {
    AssetsTypeTree
  }
}
</script>
